
import { Component, Prop, Vue } from "vue-property-decorator";
import { IPropertyInfo } from "@/models/PropertyInfo";
import DateUtils from "@/services/utils/DateUtils";
import { HousfyAnchor, HousfyMetaCard } from "housfy-ui-lib";
import HousfyLink from "@/components/protected/HousfyLink.vue";
import { IRoute } from "@/models/IRoute";
import { PropertyStatus } from "@/enums/PropertyStatus";
import { TranslateResult } from "vue-i18n";
import { IRentalLeasing, IRentalLeasings } from "@/models/RentalLeasing";
import { RentalLeasingStatus } from "@/enums/RentalLeasing";
import Money from "@/models/Money";
import LeasingsService from "@/services/LeasingsService";

@Component({
  name: "PropertySpecs",
  components: {
    HousfyAnchor,
    HousfyLink,
    HousfyMetaCard,
  },
})
export default class PropertySpecs extends Vue {
  @Prop({ type: Object, required: true })
  info!: IPropertyInfo;
  @Prop({ type: Object, required: true })
  leasings!: IRentalLeasings;
  @Prop({ type: Boolean, required: true })
  hasAccounting!: boolean;

  get title(): TranslateResult {
    if (
      this.info.propertyStatus === PropertyStatus.LOST ||
      this.info.propertyStatus === PropertyStatus.LOST_NOT_PUBLISHED
    )
      return this.$t("common.thisRentHasBeenCancelled");
    else return this.$t("common.rentalActivity");
  }

  get subtitle(): TranslateResult | null {
    if (
      !this.info.rentalMandateSignatureDate ||
      this.info.propertyStatus === PropertyStatus.LOST ||
      this.info.propertyStatus === PropertyStatus.LOST_NOT_PUBLISHED
    )
      return null;

    const { day, month, year } = DateUtils.formatDate(
      this.info.rentalMandateSignatureDate
    );
    const formattedDate =
      day + " " + this.$t("common." + month + "Short") + " " + year;

    return this.$t("common.inHousfySince", {
      date: formattedDate,
    });
  }

  get currentLeasing(): IRentalLeasing | undefined {
    return LeasingsService.getCurrentLeasing(this.leasings?.items);
  }

  get accountingSection() {
    const statusIsLost =
      this.info.propertyStatus === PropertyStatus.LOST ||
      this.info.propertyStatus === PropertyStatus.LOST_NOT_PUBLISHED;
    const leasingStatusWon =
      this.currentLeasing?.status === RentalLeasingStatus.WON;

    if (
      statusIsLost ||
      (this.info.propertyStatus === PropertyStatus.RENTAL_MANAGEMENT &&
        leasingStatusWon)
    )
      return { visible: false, disabled: false };
    return {
      visible: this.hasAccounting,
      disabled: !this.leasings.items.length,
    };
  }

  get currentYear(): string {
    return new Date().getFullYear().toString();
  }

  get annualIncome(): string {
    return new Money(
      Number(this.info.amountOfIncome.amount) / 100 -
        Number(this.info.amountOfCharges.amount) / 100
    )
      .withOptions({
        withCents: true,
        currency: this.info.amountOfIncome.currency,
      })
      .format(true);
  }

  get containerComponent(): string {
    return this.accountingSection.disabled ? "div" : "HousfyLink";
  }

  get accountingLink(): IRoute {
    return {
      name: "accounting",
      params: {
        uuid: this.info.propertyUuid,
      },
    };
  }

  get historialLink(): IRoute {
    return {
      name: "historical",
      params: {
        uuid: this.info.propertyUuid,
      },
    };
  }
}
